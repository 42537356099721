<template lang="pug">
EcreativOrganismsFooterNavigationAndLegal(:footerLinks="footerLinks")
</template>

<script setup lang="ts">
import { type Navigation, placeholderNavigationModel } from 'typed-schemas';
import EcreativOrganismsFooterNavigationAndLegal from '../organisms/FooterNavigationAndLegal.vue';

export interface FooterNavigation {
  socialLinks: Navigation;
}

const footerLinks: FooterNavigation = {
  socialLinks: placeholderNavigationModel,
} as FooterNavigation;
</script>
